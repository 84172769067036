.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensure the container takes the full viewport height */
  overflow: hidden; /* Hide any overflow */
}

.video-bg {
  position: absolute; /* Position the video absolutely within the container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
  z-index: -1; /* Places the video behind other elements */
}

.content {
  position: relative;
  z-index: 1; /* Places the content above the video */
  max-width: 800px;
  margin-top: -96px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}