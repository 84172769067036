body{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: rgb(53,53,53);

}

.title{
    font-size: 5em;
    text-align: center;
    color: #00d59a;
    font-weight: bold;
}

h3{
    padding-top: 0.25em;
    font-size: 1.5em;
}

.vertical-timeline-element-content {
    box-shadow: 0 0.25em 0.5em 0 rgba(0,0,0,0.25), 0 0.4em 1.25em 0 rgba(0,0,0,0.15) !important;
    padding: 2em 3em !important;
}

.vertical-timeline-element-title {
    font-weight: bold;
}

.date{
    color: white !important}

#description{
    margin: 1.5em 0 2em 0;
}

.button{
    text-decoration: none;
    padding: 0.5em 1em;
    border-radius: 5px;
    color: white;
}

.workButton{
    background-color: #06d6a0;
}

.workButton:hover{
    background-color: #0ac593;
}

.schoolButton{
    background-color: #f9c74f;
}

.schoolButton:hover{
    background-color: #f3bc3c;
}

@media only screen and (max-width: 1700px){
    .vertical-timeline-element-date{
        display: block !important;
        float: none !important;
        color: rgb(44,44,44);
        margin-top: 1.5em;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity/color as needed */
    z-index: 1;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
  }
  
