/* Cards.css */
.card-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity/color as needed */
  z-index: 1;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out; /* Smooth transition */
}

.card-container:hover .overlay {
  opacity: 1; /* Show the overlay on hover */
}



.slick-dots li button:hover:before {
  color: white; /* Highlight on hover */
  opacity: 1; /* Ensure visibility on hover */
}
/* Rest of your styles... */
.slick-dots li button:before {
  color: #ccc; /* Light grey color */
  font-size: 12px; /* Adjust size if needed */
}

.slick-dots li.slick-active button:before {
  color: white; /* Or any other color to distinguish active dots */
}

