/* homepage.css */
.overlay-content {
    position: relative;
    z-index: 1;
    padding: 20px;
}

.navbar, .hero {
    position: relative;
    z-index: 2;
}